import React from 'react';

const NavButton = ({ className, ...props }) => {
  return (
    <div
      {...props}
      className={`nav-button ${className} row justify-center flex-align-center`}
    >
      <div className="column flex-align-end">
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
    </div>
  );
};

export default NavButton;
