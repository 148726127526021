import { config } from 'config';
import React, { useState, createContext, useEffect, useContext } from 'react';

import * as ReactGA from 'utils/ReactGA';
import { getData, saveData } from 'utils';
import { ReactRouterGa, CookiePopup } from 'components/CookiePopup';
import AwesomeIcon from 'ui/atoms/AwesomeIcon';

const initialState = {
  necessary: false,
  analytics: false,
  marketing: false,
  noticed: false,
};
const COOKIE_NOTICE_LS = 'cookie-notice-2';

const CookieContext = createContext();
export const useCookiePopup = () => useContext(CookieContext);

export const CookieContextProvider = ({ children }) => {
  const [state, setState] = useState(getData(COOKIE_NOTICE_LS) || initialState);
  const [includePopup, setIncludePopup] = useState(!state.noticed);

  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  gtag('consent', 'default', {
    ad_storage: 'denied',
    ad_user_data: 'denied',
    ad_personalization: 'denied',
    analytics_storage: 'denied',
  });

  // handle Google Analytics
  useEffect(() => {
    if (state.noticed) saveData(COOKIE_NOTICE_LS, state);
    // eslint-disable-next-line
  }, [state.noticed, state.necessary, state.analytics, state.marketing]);

  useEffect(() => {
    if (state.noticed) {
      if (state.analytics) {
        gtag('consent', 'update', {
          analytics_storage: 'granted',
        });
        console.log('bruh1');
        ReactGA.initialize(config.googleAnalyticsId, config.googleAdwordsId);
      }

      if (state.marketing) {
        console.log('bruh2');
        gtag('consent', 'update', {
          ad_storage: 'granted',
          ad_user_data: 'granted',
          ad_personalization: 'granted',
        });
      }
    }
  }, [state.noticed, state.necessary, state.analytics, state.marketing]);

  // if (state.noticed && state.googleAnalytics) {
  //   ReactGA.initialize(config.googleAnalyticsId, config.googleAdwordsId);
  // }

  return (
    <>
      <CookieContext.Provider
        value={{
          ...state,
          setCookiePreferences: (changes) => setState({ ...state, ...changes }),
          setCookiePopupOpen: (changes) => setIncludePopup(changes),
        }}
      >
        <ReactRouterGa />
        {includePopup ? <CookiePopup /> : <CookiePopupButton />}
        {children}
      </CookieContext.Provider>
    </>
  );
};

export const CookiePopupButton = () => {
  const { setCookiePopupOpen } = useCookiePopup();

  return (
    <button id="open-cookie_popup" onClick={() => setCookiePopupOpen(true)}>
      <AwesomeIcon icon="cookie" />
    </button>
  );
};
