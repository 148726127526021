import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { mainLinks } from 'config';
import { useTrail, animated } from 'react-spring';
import { staticData } from 'config';

import { isTouchDevice } from 'utils';
// import { LanguagePicker } from './LangaugePicker';

import { DelayLinkTransition } from 'router/DelayLink';

import NavButton from './NavButton';
import NavElement from './NavElement';
import SimpleLink from 'ui/atoms/SimpleLink';
import AwesomeIcon from 'ui/atoms/AwesomeIcon';
import Media from 'ui/atoms/Media';

const locations = [
  {
    lat: 46.6381298,
    lng: 14.3233175,
    zoom: 17,
  },
];

const isTouch = isTouchDevice();

let mouseEnterTimeout;

const Nav = () => {
  const [lastLocation, setLastLocation] = useState('');
  const [top, setTop] = useState();
  const [open, setOpen] = useState(false);
  const [transition, setTransition] = useState();
  const [activeImage, setActiveImage] = useState('');
  const [activeSub, setActiveSub] = useState('');

  const { pathname } = useLocation();

  useEffect(() => {
    setActiveImage(
      mainLinks.findIndex(({ link }) => pathname.search(link) !== -1),
    );
    if (lastLocation && lastLocation !== pathname) {
      setOpen(false);
      setTransition(true);
      setTimeout(() => {
        setTransition(false);
      }, 900);
      // setTimeout(() => {
      //   setTransition(false);
      // }, 1000);
    }
    setLastLocation(pathname);
  }, [pathname, lastLocation]);

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];
    const root = document.getElementById('root');
    setTop(window.pageYOffset);

    if (open) {
      body.style.height = '0vh';
      body.style.overflow = 'hidden';

      setTimeout(() => {
        root.style.height = '100vh';
        root.style.overflow = 'hidden';
      }, 500);
    }
    if (!open) {
      body.style.height = '100%';
      body.style.overflow = 'auto';
      root.style.height = 'auto';
      root.style.overflow = 'auto';

      window.scrollTo(0, top);
    }
  }, [open]);

  const toggleOpen = open ? 'open' : '';

  const transitions = useTrail(mainLinks.length, {
    config: { mass: 1, tension: 500, friction: 34 },
    delay: open ? 500 : 0,
    opacity: open ? 1 : 0,
    y: open ? 0 : 20,
    from: { opacity: 0, y: 20 },
  });

  return (
    <>
      <nav
        style={{ padding: 0 }}
        className={`${
          isTouch ? 'touch-support' : ''
        } row justify-space-between flex-align-stretch ${toggleOpen} ${
          transition ? 'anim' : ''
        }`}
      >
        <TopBar />
        {/* <LeftBar /> */}

        <div className="nav__content">
          <div className="row fw fh flex-align-stretch">
            <div className="row col-md-8 nop content remove-on-mobile">
              <div
                className="row col-md-12 justify-center flex-align-center nop overflow-hidden"
                style={{ height: '50vh' }}
              >
                {/* <Mapbox locations={locations} {...locations[0]} /> */}
                <Media
                  src="/logo.png"
                  style={{ maxWidth: 500, height: 'auto' }}
                />
              </div>
              <div
                className="row col-md-12 nop bg-primary-dark"
                style={{ height: '50vh' }}
              >
                <div className="row col-md-6 nop justify-center flex-align-center white">
                  <div className="align-center">
                    <h3 className="font-tertiary ">Rechtliches</h3>
                    <p className="nom">
                      <DelayLinkTransition to="/datenschutz" className="link">
                        Datenschutz
                      </DelayLinkTransition>
                    </p>
                    <p className="nom">
                      <DelayLinkTransition to="/impressum" className="link">
                        Impressum
                      </DelayLinkTransition>
                    </p>
                  </div>
                </div>
                <div className="row col-md-6 nop justify-center flex-align-center bg-tertiary-light">
                  <div className="align-center ">
                    <h3 className="font-tertiary primary">Kontakt</h3>
                    <a href={staticData.tel.href} className="link">
                      <p>{staticData.tel.text}</p>
                    </a>
                    <a href={staticData.email.href} className="link">
                      <p>{staticData.email.text}</p>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="row col-md-4 flex-align-center justify-center-md pt-md-10 pl-10 pl-md-1">
              <div>
                {transitions.map(({ y, ...rest }, i) => {
                  return (
                    <animated.div
                      key={i}
                      style={{
                        ...rest,
                        transform: y.to((y) => `translate3d(0,${y}px,0)`),
                      }}
                    >
                      <NavElement
                        {...mainLinks[i]}
                        onMouseEnter={() => {
                          if (isTouch) return;
                          clearTimeout(mouseEnterTimeout);
                          mouseEnterTimeout = setTimeout(() => {
                            setActiveImage(i);
                          }, 200);
                          if (!mainLinks[i].submenu) return;
                          setActiveSub(i);
                        }}
                        onClick={(e) => {
                          if (!isTouch) return;
                          setActiveImage(i);
                          if (!mainLinks[i].submenu) return;
                          if (activeSub === i) return;
                          e.preventDefault();
                          setActiveSub(i);
                        }}
                        activeSub={activeSub === i}
                      />
                    </animated.div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </nav>
      <NavButton className={`${toggleOpen}`} onClick={() => setOpen(!open)} />
      <Link
        aria-label="Home"
        to="/"
        className="header-logo row flex-align-center"
      >
        <img src="/logo.png" alt="" />
      </Link>
    </>
  );
};

const TopBar = () => {
  const { email, tel } = staticData;

  return (
    <div className="top-bar fw row flex-align-center justify-space-between show-on-mobile">
      <SimpleLink
        to="/"
        className="column logo-link justify-center flex-aign-center"
      >
        <img
          loading="lazy"
          className="logo-icon"
          src="logo.png"
          alt="some logo"
        />
      </SimpleLink>
      <div className="contact-info row justify-space-between">
        <div>
          <a className="simple-hover mr-4" href={tel.href}>
            <AwesomeIcon className="mr-1" icon="phone" />
            <span>{tel.text}</span>
          </a>
          <a
            className="simple-hover mr-4"
            href={email.href}
            target="_blank"
            rel="noreferrer noopener"
          >
            <AwesomeIcon className="mr-1" icon="envelope" />
            <span>{email.text}</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Nav;
