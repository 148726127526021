import React, { useState } from 'react';
import { useCookiePopup } from './CookieContext';
import map from 'lodash/map';

import CheckBox from 'ui/atoms/CheckBox';
import Button from 'ui/atoms/Button';

const data = {
  necessary: {
    label: 'Notwending',
    // desc: 'test',
  },
  analytics: {
    label: 'Tracking & Statistiken',
    // desc: 'test',
  },
  marketing: {
    label: 'Marketing',
    // desc: 'test',
  },
};

export const CookiePopup = () => {
  const { setCookiePreferences, setCookiePopupOpen, noticed, ...cookieState } =
    useCookiePopup();

  const handleOnChange = ({ target: { name, checked } }) => {
    setCookiePreferences({
      [name]: checked,
    });
  };

  console.log(cookieState);

  return (
    <div className="cookie-notice__container row justify-end flex-align-end p-2 p-x2 pr-x3">
      <div className="cookie-notice">
        <div className="form-group align-center">
          <h3 className=" fw-bold">Wir verwenden Cookies</h3>
        </div>
        <div className="form-group pb-x3 gray-300 pb-2 align-center">
          <p>
            Mit der Nutzung dieser Website erklären Sie sich damit
            einverstanden, dass wir Cookies verwenden. Weitere Informationen
            dazu finden Sie in unserem Datenschutz.
          </p>
        </div>

        <div
          className="pb-x3 row flex-align-stretch "
          style={{ margin: '0 -8px' }}
        >
          {map(cookieState, (value, key) => (
            <div className="col-md-12">
              <div
                key={key}
                className="form-group checkbox-container row flex-align-center justify-space-between"
              >
                <label>
                  <div className="h6 fw-bold">{data[key].label}</div>
                  {/* <p>{data[key].desc}</p> */}
                </label>
                <CheckBox
                  checked={key === 'necessary' ? true : value}
                  onChange={key === 'necessary' ? null : handleOnChange}
                  name={key}
                />
              </div>
            </div>
          ))}
        </div>

        <div className="row justify-space-between">
          <div className="col-md-4">
            <Button
              className="fw btn-cookie-accept-selection"
              onClick={() => {
                setCookiePreferences({ noticed: true });
                setCookiePopupOpen(false);
              }}
            >
              Auswahl Akzeptieren
            </Button>
          </div>
          <div className="col-md-4">
            <Button
              className="fw btn-cookie-accept-nothing"
              onClick={() => {
                setCookiePreferences({
                  necessary: true,
                  analytics: false,
                  marketing: false,
                  noticed: true,
                });
                setCookiePopupOpen(false);
              }}
            >
              Nichts Akzeptieren
            </Button>
          </div>
          <div className="col-md-4">
            <Button
              className="fw btn-cookie-accept-all"
              onClick={() => {
                setCookiePreferences({
                  necessary: true,
                  analytics: true,
                  marketing: true,
                  noticed: true,
                });
                setCookiePopupOpen(false);
              }}
            >
              Alles Akzeptieren
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
