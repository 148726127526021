import React from 'react';

import { NavLink } from 'react-router-dom';

const NavElement = ({ text, link, subMenu, ...props }) => {
  if (text.search('http') !== -1) {
    return (
      <a
        target="_blank"
        rel="noopener noreferrer"
        className="h1 row flex-align-center nav-link nav-hover font-tertiary black"
        href={link}
      >
        <span>{text}</span>
      </a>
    );
  } else {
    return (
      <NavLink
        {...props}
        exact
        className="h1 row flex-align-center nav-link nav-hover font-tertiary black mb-2 mb-md-0"
        to={link}
      >
        <span>{text}</span>
      </NavLink>
    );
  }
};

export default NavElement;