import React from 'react';
import { staticData, legalLinks } from 'config';

import AwesomeIcon from 'ui/atoms/AwesomeIcon';
import Media from 'ui/atoms/Media';

import { DelayLinkTransition } from '../DelayLink';

export const Footer = () => {
  const { address, email, tel, companyName } = staticData;
  return (
    <>
      <footer className="footer col shadow-small pb-0 pt-0">
        <div className="row container justify-center">
          <div className="row col-md-4 flex-align-center">
            <div
              className="bg-primary p-2 mr-2 white pulse row justify-center flex-align-center"
              style={{ borderRadius: '99px', width: '60px', height: '60px' }}
            >
              <AwesomeIcon icon="phone" />
            </div>
            <div>
              <h6 className="font-tertiary primary fw-bold nom">
                Jetzt anrufen
              </h6>
              <a className="link" href={tel.href}>
                {tel.text}
              </a>
            </div>
          </div>

          <div className="row col-md-4 flex-align-center">
            <div
              className="bg-primary p-2 mr-2 white pulse row justify-center flex-align-center"
              style={{ borderRadius: '99px', width: '60px', height: '60px' }}
            >
              <AwesomeIcon icon="envelope" />
            </div>
            <div>
              <h6 className="font-tertiary primary fw-bold nom">
                Kontaktieren Sie mich
              </h6>
              <a className="link" href={email.href}>
                {email.text}
              </a>
            </div>
          </div>
        </div>

        <hr className="fw bg-tertiary-light mb-4" style={{ height: '1px' }} />

        {/* <div
          className="row container bg-primary justify-end white flex-align-center"
          style={{
            backgroundImage: 'url(/assets/background/background-small.png)',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }}
        >
          <div className="col-md-6">
            <h5 className="fw-black uppercase nom align-right pr-2">
              Ihr Kanalreiniguns<br />
              Dienstleister
            </h5>
          </div>
          <DelayLinkTransition to="/kontakt">
            <Button className="secondary" label="ANGEBOT EINHOLEN" />
          </DelayLinkTransition>
        </div> */}

        <div className="row container white justify-center">
          <div className="col-md-3 mb-4">
            <Media src="/logo.png" />
          </div>
          {/* <div className="col-md-2">
            <h6 className="font-tertiary primary fw-bold">Informationen</h6>
            <p className="nom small">
              <DelayLinkTransition to="/notdienst" className="link">
                Notdienst
              </DelayLinkTransition>
            </p>
            <p className="nom small">
              <DelayLinkTransition to="/news" className="link">
                News
              </DelayLinkTransition>
            </p>
            <p className="nom small">
              <DelayLinkTransition to="/karriere" className="link">
                Karriere
              </DelayLinkTransition>
            </p>
            <p className="nom small">
              <DelayLinkTransition to="/kontakt" className="link">
                Kontakt
              </DelayLinkTransition>
            </p>
          </div> */}
          {/* <div className="col-md-3 pl-md-5">
            <h6 className="font-tertiary primary fw-bold">Inhalt</h6>
            <p className="nom small">
              <DelayLinkTransition to="/" className="link">
                Home
              </DelayLinkTransition>
            </p>
            <p className="nom small">
              <DelayLinkTransition to="/impressum" className="link">
                Impressum
              </DelayLinkTransition>
            </p>
            <p className="nom small">
              <DelayLinkTransition to="/datenschutz" className="link">
                Datenschutz
              </DelayLinkTransition>
            </p>
            <p className="nom small">
              <DelayLinkTransition to="/agb" className="link">
                AGB
              </DelayLinkTransition>
            </p>
          </div> */}
          {/* <div className="col-md-3">
            <h6 className="font-tertiary primary fw-bold">Newsletter</h6>
            <Newsletter />
          </div> */}
        </div>

        <hr className="fw bg-tertiary-light" style={{ height: '1px' }} />

        <div className="footer__bottom container fw align-center row nop justify-space-between ">
          <span className="fs-small p-2">
            © COPYRIGHT {new Date().getFullYear()} - {companyName}
          </span>
          <div className="row col nop justify-center">
            {legalLinks.map(({ link, text, normalLink }) =>
              !normalLink ? (
                <DelayLinkTransition key={link} className="mr-1 p-2" to={link}>
                  <span className="simple-hover">{text}</span>
                </DelayLinkTransition>
              ) : (
                <a
                  className="simple-hover mr-1 p-2 block"
                  key={text}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={link}
                >
                  {text}
                </a>
              ),
            )}
          </div>
        </div>
      </footer>
    </>
  );
};
