import Home from 'assets/images/header/home.jpg';

export const mainLinks = [
  {
    link: '/',
    text: 'Home',
    img: Home,
  },
  { link: '/impressum', text: 'Impressum' },
  { link: '/datenschutz', text: 'Datenschutz' },
  // { link: '/agb', text: 'AGB' },
];

export const legalLinks = [
  { link: '/impressum', text: 'Impressum' },
  { link: '/datenschutz', text: 'Datenschutz' },
  // { link: '/agb', text: 'AGB' },
];
