export const staticData = {
  companyName: 'Anna Maria Sari BSc - Logopädin',
  email: {
    href: 'mailto:praxis@logopaedie-sari.at',
    text: 'praxis@logopaedie-sari.at',
    target: '__blank',
  },
  tel: {
    href: 'tel:00436645455962',
    text: '+43 (0) 664 / 54 55 962',
  },
  address: ['Salzgasse 3', '9020 Klagenfurt'],
  website: {
    href: 'https://www.logopaedie-sari.at',
    text: 'www.logopaedie-sari.at',
  },
  facebookLink: 'https://www.facebook.com/',
  instagramLink: 'https://www.instagram.com/',
  twitterLink: 'https://www.twitter.com/',
};
