import { useEffect } from 'react';
import { useLocation } from 'react-router';
import * as ReactGa from 'utils/ReactGA';
import { useCookiePopup } from './CookieContext';

export const ReactRouterGa = ({ children }) => {
  const { pathname } = useLocation();
  const { noticed, analytics } = useCookiePopup();

  useEffect(() => {
    if (noticed && analytics) {
      ReactGa.pageview(pathname);
    }
  }, [pathname, noticed, analytics]);

  return children || null;
};
